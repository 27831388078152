import React, {useState, useEffect, useRef} from "react";
import { MapInteraction } from 'react-map-interaction';
import Api from "../utils/Api";
import {URL_ZONES} from "../constants/urls";


const MapContainer = ({
                          scaleState,
                          currentData,
                          currentCategory,
                          refContainerMap,
                          hoverOnArea,
                          hoverOutArea,
                          setScaleState,
                          initialScale,
                          clickOnArea,
                          touchArea,
                          mapTranslation,
                          onMapTranslationChange,
                          inBox,
                          fullVisible,
                          clickedArea,
                          colors,
                          language,
                          service,
                          view,

                      }) => {


    const [imageOverflow, setImageOverflow] = useState({
        xMin: 0,
        xMax: 0,
        xyMin: 0,
        yMax: 0
    });

    const [divSize, setDivSize] = useState({
        width: '100%',
        height: '100%',
    });

    const [imageSize, setImageSize] = useState({
        width: null,
        height: null,
    });

    const [mapValue, setMapValue] = useState({scale: scaleState, translation: mapTranslation});

    const refImageMap = useRef(null);

    const setImageOverflowFromBounding = () => {

        const containerRect = refContainerMap.current.getBoundingClientRect();
        const childRect = refImageMap.current.getBoundingClientRect();

        setImageOverflow({
            // drag vers la gauche
            xMin: childRect.width > containerRect.width ? (childRect.width  - containerRect.width)*-1 : 0,
            xMax: 0,
            yMin: childRect.height > containerRect.height ? (childRect.height - containerRect.height)*-1 : 0,
            yMax: 0
        });

    }

    const setDivDimensions = () => {

        const childRect = refImageMap.current.getBoundingClientRect();
        setDivSize({
            width: childRect.width,
            height: childRect.height,
        });


        // on définit un état de scale (la taille du container divisée par la taille de l'image)
        // qui nous permettra d'afficher le svg en entier dans la fenetre, quelle que soit la taille de la fenetre
        setScaleState(0);


        setMapValue({
            scale: initialScale,
            translation: {x:0, y:0}
        });
    }

    const setImageDimensions = () => {

        const childRect = refImageMap.current.getBoundingClientRect();
        setImageSize({
            width: currentData.width * mapValue.scale,
            height: currentData.height * mapValue.scale
        })

    }

    const handleImageEvent = (scale, translation) => {

        //Quand on drag vers la gauche, si l’image n’a plus de contenu en dehors du container à droite
        //on met la valeur xMin à 0


        setImageOverflowFromBounding();

        setScaleState(scale);

        onMapTranslationChange(translation);

        setMapValue({
            scale: scale,
            translation: translation
        });

        setImageSize({
            width: currentData.width * scale,
            height: currentData.height * scale
        })
    }

    useEffect(() => {

        setImageOverflowFromBounding();
        setDivDimensions();
        setImageDimensions();

    }, [currentData.imageBackground]);


    return (
        <MapInteraction

            minScale = {initialScale}
            // on autorise le zoom à trois fois la taille initiale
            maxScale = {scaleState * 3}

            value = {mapValue}

            onChange = {(value) => {
                handleImageEvent(value.scale, value.translation);
            }}

            showControls={true}

            // Class applied to the controls wrapper (only when 'showControls' is 'true')
            controlsClass="containerZoomMap"

            // Class applied to the plus/minus buttons (only when 'showControls' is 'true')
            btnClass="zoomBtnMap"

            // Classes applied to each button separately (only when 'showControls' is 'true')
            plusBtnClass="zoomInMap"
            minusBtnClass="zoomOutMap"


            translationBounds = {{
                // drag vers la gauche
                xMin: imageOverflow.xMin,
                // drag vers la droite
                xMax: 0,
                // drag vers le haut
                yMin: imageOverflow.yMin,
                // drag vers le bas
                yMax: 0

            }}


        >
            {
                ({ translation, scale }) => {

                    let calculatedScale = initialScale;
                    let withFilterBar = 75;

                    if (refContainerMap && refImageMap.current) {

                        const containerRect = refContainerMap.current.getBoundingClientRect();
                        let isInitialTranslation = translation.x === 0 && translation.y === 0;

                        if (inBox) {

                            if (
                                !fullVisible && (
                                    imageSize.width > containerRect.width ||
                                    imageSize.height < containerRect.height
                                )
                            )
                            {
                                calculatedScale = initialScale / 0.75;
                            }

                            if (isInitialTranslation && inBox) {
                                translation.x = (containerRect.width - imageSize.width) / 2;
                            }

                            if (isInitialTranslation && inBox) {
                                translation.y = (containerRect.height - imageSize.height) / 2;
                            }

                        }

                    }

                    const transform = `matrix(${scale},0,0,${scale},${translation.x},${translation.y})`;
                    const divHeight = Math.floor(calculatedScale * currentData.height)+withFilterBar;

                    return (
                        <div
                            style={{
                                height: divHeight,
                                width: '100%',
                                position: 'relative', // for absolutely positioned children
                                overflow: 'hidden',
                                touchAction: 'none', // Not supported in Safari :(
                                msTouchAction: 'none',
                                cursor: 'all-scroll',
                                WebkitUserSelect: 'none',
                                MozUserSelect: 'none',
                                msUserSelect: 'none',
                                // paddingBottom: '75px'
                            }}
                        >
                            <svg
                                width={'100%'}
                                height={"100%"}
                                preserveAspectRatio={"xMidYMid meet"}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <g transform={transform} ref={refImageMap}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        width={currentData.width}
                                        height={currentData.height}
                                        data-view={currentData.view}
                                        name={"tnlmap"}
                                    >

                                        {currentData.imageBackground ?
                                            <image
                                                xlinkHref={currentData.imageBackground}
                                                width={currentData.width}
                                                height={currentData.height}
                                            />
                                            :
                                            <rect width={"100%"} height={"100%"} style={{ fill: 'rgba(255,255,255,0)' }}></rect>
                                        }

                                        {/* pour chaque area (premier ou second niveau, on rend un svg cliquable et hoverable */}
                                        {Object.keys(currentData.areas).map(area => {

                                            const currentArea = currentData.areas[area];

                                            // vérifie que les catégories de la zones sont bien actives
                                            // (une catégorie peut-être disabled lors de ventes échelonnées)
                                            let areCategoriesEnabled = false;
                                            currentArea.cat.map(areaCat => {
                                                currentData.categories.map(category => {
                                                    if (areaCat === category.id) {
                                                        areCategoriesEnabled |= (!('enabled' in category) || category.enabled);
                                                    }
                                                });
                                            });

                                            if (!areCategoriesEnabled) {
                                                return false;
                                            }

                                            let avColor = (currentArea.fill ? currentArea.fill : colors.background);
                                            avColor = (avColor == '#ffffff' ? 'red' : avColor);

                                            // si la zone actuelle est la zone qui est cliquée et que c'est un siège
                                            // alors on passe isClickedSeat à true
                                            let isClickedSeat = false;
                                            if (clickedArea) {
                                                if (clickedArea.id === currentArea.id && currentArea.seat) {
                                                    isClickedSeat = true;
                                                }
                                            }

                                            let sep = "";
                                            let classes = currentArea.seat ? 'seats ' : 'zones ';

                                            if (currentArea.av==='1') {
                                                classes+= "area--available";
                                                sep = " ";
                                            }
                                            else {
                                                sep = " ";
                                                classes+= "area--unavailable";
                                                if (service === 'dsp') {
                                                    classes+= sep+"dsp-area--unavailable";
                                                }
                                            }

                                            if (isClickedSeat) {
                                                classes+= sep+"area--current";
                                                sep = " ";
                                            }

                                            if (currentArea.sel==='1') {
                                                classes+= sep+"area--selected";
                                                sep = " ";
                                            }

                                            if (currentArea.typ==='1') {
                                                classes+= sep+"area--type-strapontin";
                                                sep = " ";
                                            }

                                            if (currentCategory) {

                                                var hasCategory = false;
                                                currentArea.cat.map(category => {
                                                    if (category == currentCategory) {
                                                        hasCategory = true;
                                                    }
                                                });

                                                if (!hasCategory) {
                                                    classes+= sep+"area--unhighlighted";
                                                    sep = " ";
                                                }

                                            }

                                            let opacity = (currentArea.filo ? currentArea.filo : 1);
                                            if (currentArea.av && service === 'dsp') {
                                                opacity = 0
                                            } else if (!currentArea.av && service === 'dsp') {
                                                opacity = 1
                                            }

                                            return (
                                                // créer un tag avec le shape définie dans le central api
                                                //sh = shape
                                                <currentArea.sh
                                                    key={currentArea.id}
                                                    className={classes}
                                                    cursor={"pointer"}
                                                    fill={isClickedSeat ? "#40de40" : (currentArea.av ? avColor : "grey")}
                                                    fillOpacity={opacity}
                                                    {...currentArea.sha}
                                                    onMouseEnter={(e) => hoverOnArea(e, currentArea)}
                                                    onMouseLeave={(e) => hoverOutArea(e, currentArea)}
                                                    onMouseDown={(e) => clickOnArea(e, currentArea)}
                                                    onTouchEnd={(e) => touchArea(e, currentArea)}
                                                />
                                            );


                                        })}

                                    </svg>
                                </g>
                            </svg>
                        </div>

                    )

                }
            }


        </MapInteraction>
    );
}

export default MapContainer;
