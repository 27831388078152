// url d'appel pour les zones (temporaire à DSP)

export const BASE_URL = window.location.origin;

// url pour récupérer les zones du plan
export const URL_ZONES = (packId, sessionId, idwl) => {

  let parameters = "";
  if (idwl != null) {
    let sep = parameters.length ? "&" : "?";
    parameters += sep + "idwl=" + idwl;
  }

  return BASE_URL + "/map/" + (packId ? packId : '0') + "/" + sessionId + "/zones" + parameters;
};

// url pour récupérer les sièges du plan
export const URL_SEATS = (packId, sessionId, areaId, idwl) => {

  let parameters = "";
  if (idwl != null) {
    let sep = parameters.length ? "&" : "?";
    parameters += sep + "idwl=" + idwl;
  }

  return BASE_URL + "/map/" + (packId ? packId : '0') + "/" + sessionId + "/area/" + areaId + "/zones" + parameters;

};


// url pour ajouter un siège au panier


export const URL_ADD_SEAT = (packId, sessionId, service, cartId, idwl, sessionList, subscriptionId) => {

  let cartParameter = cartId != null ? "?cartId=" + cartId : "";
  if (idwl != null) {

    let sep = cartParameter.length ? "&" : "?";
    cartParameter += sep + "idwl=" + idwl;

  }

  if (sessionList != null && sessionList.length) {

    sessionList.forEach(function(sessionListId) {
      let sep = cartParameter.length ? "&" : "?";
      cartParameter += sep + "sessions[]=" + sessionListId;
    });

  }

  if (subscriptionId != null) {

    let sep = cartParameter.length ? "&" : "?";
    cartParameter += sep + "subscription=" + subscriptionId;

  }

  return BASE_URL + "/map/" + (packId ? packId : '0') + "/" + sessionId + "/service/" + service + "/add" + cartParameter;
};

