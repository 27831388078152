export const TEXTS = Object.freeze({
    fr: {
        back: 'Retour',
        from: 'à partir de',
        introduction: 'Choisissez vous-même vos sièges.',
        filter: 'Filtrez par :',
        validate: 'Valider',
        adding: 'Mise au panier...',
        rank: 'Rang',
        seat: 'Siège',
        area: 'Accès',
        unavailable: 'Cette place n\'est plus disponible !'
    },
    en: {
        back: 'Back',
        from: 'from',
        introduction: 'Choose you seats on the map.',
        filter: 'Filter by :',
        validate: 'Validate',
        adding: 'Adding to cart...',
        rank: 'Row',
        seat: 'Seat',
        area: 'Acces',
        unavailable: 'This seat is now unavailable !'
    }
});
