export const getScaleRate = (inBox, fullVisible, containerWidth, containerHeight, imageWidth, imageHeight, contentRatio) => {

    let initialScale = Math.min(1, containerWidth / imageWidth);
    if (inBox) {
        let ratioHeight = containerHeight / imageHeight;
        let ratioWidth = containerWidth / imageWidth;
        initialScale = fullVisible ? Math.min(ratioWidth, ratioHeight) : Math.max(ratioWidth, ratioHeight)*contentRatio;
    }

    return initialScale;
};
