import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import ModalTooltip from "../components/ModalTooltip";
import Loader from "../components/Loader";
import MapContainer from "../containers/MapContainer";
import MapFilter from "../components/MapFilter";

import Api from "../utils/Api";
import { URL_ZONES, URL_SEATS, URL_ADD_SEAT } from "../constants/urls";
import { THEME_COLORS } from "../constants/colors";
import { TEXTS } from "../constants/language";
import { MapInteraction } from "react-map-interaction";
import {getScaleRate} from "../utils/scaleCalculator";


// funct component qui récupère la props session ID
const MainContainer = forwardRef((props, ref) => {
  const {
    sessionId,
    sessionList,
    packId,
    cartId,
    idwl,
    maxSeats,
    subscriptionId,
    onSeatAdded,
    onSeatSelected,
    onBooked,
    holders,
    inBox,
    fullVisible,
    autoload,
    colors,
    language,
  } = props;

  // on définit le state rootData, qui sera hydraté par le premier appel vers le central api (en fonction des WS renvoie uniquement les zones (area de premier niveau), ou les zones + sièges (area de second niveau) directement)
  // sera enrichit au fur et à mesure de l'appel des zones
  const [rootData, setRootData] = useState([]);

  // image bg + liste des areas à cliquer (peut être zone ou siège, ou les deux)
  const [isInBox, setInBox] = useState(inBox);

  // est-ce que le plan doit être entièrement visible à l'initialisation s'il est dans une popup
  const [isFullVisible, setFullVisible] = useState(fullVisible);

  const [navMode, setNavMode] = useState("desktop");

  // image bg + liste des areas à cliquer (peut être zone ou siège, ou les deux)
  const [initialized, setInitialized] = useState(false);

  // image bg + liste des areas à cliquer (peut être zone ou siège, ou les deux)
  const [currentData, setCurrentData] = useState([]);

  const [mapTranslation, setMapTranslation] = useState({ x: 0, y: 0 });

  const [bookedSeats, setBookedSeats] = useState([]);

  const [scaleState, setScaleState] = useState();

  const [initialScale, setInitialScale] = useState();

  // area hover (zone ou siège) pour permettre l'affichage de la modalTooltip
  const [hoveredArea, setHoveredArea] = useState(null);

  // clickedArea, pour permettre de fixer la modal
  const [clickedArea, setClickedArea] = useState(null);

  const [isSeatArea, setIsSeatArea] = useState(false);

  const [cartIdState, setCartIdState] = useState(cartId);

  const [isLoading, setIsLoading] = useState(true);

  const [currentElement, setCurrentElement] = useState(null);

  const [currentCategory, setCurrentCategory] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState([]);

  const [availableHolders, setAvailableHolders] = useState(holders);

  const [warningRulesHTML, setWarningRulesHTML] = useState(null);

  const [authorizeDesignation, setAuthorizeDesignation] = useState(null);

  // on créé une reference avec une valeur à null
  // en utilisant le hook useRef
  const refContainerMap = useRef(null);

  // on créé une référence à la modal du composant enfant ModalTooltip (composant créé avec forwardRef
  // pour avoir accès à la ref de la modal ici)
  const refModal = useRef();

  useEffect(() => {
    if (cartId && cartId !== cartIdState) {
      setCartIdState(cartId);
    }
  });

  // === componentDidMount
  // est appelé une seule fois car pas d'inputs passés en param
  // permet de faire le premier appel à l'api et d'hydrater le rootData et le currentData
  useEffect(() => {
    if (!initialized && autoload) {
      initialize();
    }
  }, [autoload]);

  // au changement d'etat de la refModal et de la clickedArea
  useEffect(() => {
    const handleContainerClick = (e) => {
      // si la refModal existe (donc que la modal est ouverte)
      // et que la clickedArea a été set (donc qu'on est pas sur un premier clic d'ouverture de la modal)
      if (
          refModal.current &&
          !refModal.current.contains(e.target) &&
          clickedArea
      ) {
        // alors on cache la modal
        hideClickedModal();
      }
    };

    // on ajoute un event listener pour écouter le click sur la page
    document.addEventListener("mousedown", handleContainerClick);

    // Après avoir géré le click, on supprime l'eventListener sur le click
    return () => {
      document.removeEventListener("mousedown", handleContainerClick);
    };
  }, [refModal, clickedArea]);

  // A chaque fois que l'état currentElement est modifié
  useEffect(() => {
    // si l'état currentElement contient une zone
    if (currentElement && navMode === "desktop") {
      // si on est pas sur un click ou sur un drag
      if (!clickedArea) {
        // donc on sticke la modale
        document.addEventListener("mousemove", stickModal);
      }

      // le return est l'équivalent du componentWillUnMount
      // permet généralement de nettoyer ce qui a été fait au changement d'état
      return () => {
        document.removeEventListener("mousemove", stickModal);
      };
    }
  }, [currentElement]);

  const initialize = () => {
    setIsLoading(true);
    setInitialized(true);

    Api.get(URL_ZONES(packId, sessionId, idwl)).then((res) => {
      const data = res["data"];

      setAuthorizeDesignation(data.authorizeDesignation);

      // on récupère la taille du container, définie en css
      const width = refContainerMap.current
          ? refContainerMap.current.offsetWidth
          : 0;

      let initialScale = getScaleRate(isInBox,isFullVisible,refContainerMap.current.offsetWidth, refContainerMap.current.offsetHeight, data.width, data.height, 0.75);
      let initialTranslation = { x: 0, y: 0 };


      // on définit un état de scale (la taille du container divisée par la taille de l'image)
      // qui nous nous permettra d'afficher le svg en entier dans la fenetre, quelle que soit la taille de la fenetre
      setScaleState(initialScale);
      setInitialScale(initialScale);
      setMapTranslation(initialTranslation);
      setIsLoading(false);

      setRootData(data);
      setCurrentData(data);

      // Si il n'y a qu'une catégorie disponible, on la met en avant
      if (data.categories.length === 1) {
        let category = data.categories[0];
        toggleCategory(category['id']);
      }

    });
  };

  const handleMapTranslationChange = (mapTranslation) => {
    setMapTranslation(mapTranslation);
  };

  const toggleCategory = (idCategory) => {
    let typedId = idCategory ? idCategory.toString() : null;

    if (currentCategory && currentCategory === typedId) {
      setCurrentCategory(null);
    } else {
      setCurrentCategory(typedId);
    }
  };

  // attache la modale (utilisé avec un event listener soit de scroll, soit normal)
  const stickModal = (e) => {
    if (refModal.current !== null && typeof refModal.current != "undefined") {
      let modal = refModal.current;
      refModal.current.style.display = "block";
      //
      // // Les positions de l'élément cliqué
      // let elemX = currentElement.getBoundingClientRect().left + 10;
      // let elemY = currentElement.getBoundingClientRect().top + 10;
      //
      // // Par défaut la popup se positionne à 10px de l'élément cliqué
      // var left = elemX;
      // var top = window.scrollY + elemY;

      let elemX = e.offsetX;

      let paddingTop = 0;
      let paddingModalTop = 50;

      // La distance entre le point et le div global de la map
      // (div parent de la modal)
      let globalContainerToAreaPoint = e.offsetY + refContainerMap.current.offsetTop

      let left = e.offsetX + 20;
      let top = globalContainerToAreaPoint + 20;

      //  Si l'élément cliqué est dans la partie droite de l'écran,
      //  on l'affiche sur la partie gauche
      let halfScreenWidth = window.innerWidth / 2;
      if (elemX > halfScreenWidth) {
        left = Math.max(0, elemX - (modal.offsetWidth + 30));
      }

      // Si, à son placement calculé, la modal dépasse de l'écran,
      // on la colle au bord gauche
      if (left + modal.offsetWidth > window.innerWidth) {
        left = 0;
      }

      // Place la popup sur son côté
      modal.style.left = left + "px";

      // Si la modal est par dessus le point, on la place en dessus ou en dessous
      let isAreaUnderModal = left < e.offsetX &&  (left + modal.offsetWidth) > e.offsetX;
      if (isAreaUnderModal) {

        // Si la modal dépasse le bas de l'écran
        if (!isFullVisibleBottom(e.clientY, modal)) {

          // Si la modal dépasse aussi le haut de l'écran
          if (!isFullVisibleTop(e.clientY, modal)) {
            top = 0;
            modal.style.height = window.innerHeight - (paddingTop + paddingModalTop) + "px";
            modal.style.overflow = "scroll";

          } else {
            top = globalContainerToAreaPoint - modal.offsetHeight - 20;
          }
        }
      }
      // sinon on la place sur le côté
      else {
        top = globalContainerToAreaPoint - (modal.offsetHeight/2);
      }


      modal.style.top = top + "px";

      //
      // if (top + modal.offsetHeight > window.screen.height) {
      //     refContainerMap.current.style.marginBottom = (window.screen.height - (top + modal.offsetHeight)) + "px";
      // }
      // else {
      //     refContainerMap.current.style.marginBottom = 0;
      // }
    }
  };

  const isInTopPart = (elemY) => {
    return elemY < window.innerHeight / 2;
  };

  const isFullVisibleTop = (elemY, modal) => {
    // 105 = taille du bouton valider + bouton fermer la modale au clic sur une place
    return modal.scrollHeight + 105 <= elemY;
  };

  const isFullVisibleBottom = (elemY, modal) => {
    // 105 = taille du bouton valider + bouton fermer la modale au clic sur une place
    return elemY + modal.scrollHeight + 105 <= window.innerHeight;
  };

  // au click sur une area
  // à inverser avec les appel d'api
  const clickOnArea = (e, clickedArea) => {
    let rootDataArea = rootData.areas[clickedArea.id];
    let hasCategory = hasAreaActiveCategory(clickedArea);
    if (!hasCategory) {
      return;
    }

    if (clickedArea.av === "0") {
      return;
    }

    // si c'est une area de second niveau
    if (clickedArea.seat && clickedArea.av === "1") {
      if (!hasReachedMaxSeats()) {

        document.removeEventListener("mousemove", stickModal);

        // e.nativeEvent retourne null sur mobile
        if (window.screen.width > 1024) {
          stickModal(e.nativeEvent);
        } else {
          stickModal(e);
        }

        setClickedArea(clickedArea);

        setIsSeatArea(true);

        setCurrentElement(e.target);
      }

      // si on a pas reçu du central api les zones de second niveau (les sièges)
    } else if (rootDataArea.data === null) {
      hideClickedModal();

      setIsLoading(true);

      Api.get(URL_SEATS(packId, sessionId, clickedArea.id, idwl)).then(
          (res) => {
            const data = res["data"];

            let initialScale = getScaleRate(isInBox,isFullVisible,refContainerMap.current.offsetWidth, refContainerMap.current.offsetHeight, data.width, data.height, 0.75);

            // on définit un état de scale (la taille du container divisée par la taille de l'image)
            // qui nous nous permettra d'afficher le svg en entier dans la fenetre, quelle que soit la taille de la fenetre
            setScaleState(initialScale);
            setInitialScale(initialScale);

            // on stocke les données des sièges reçus dans la variable rootDataArea
            rootDataArea.data = data;

            // on créé une copie du rootData
            let newRootData = rootData;

            // on insère les données des sièges reçus pour l'area concerné dans la copie du rootData
            newRootData.areas[clickedArea.id] = rootDataArea;

            // on remplace le rootData par la copie du rootData mise à jour
            setRootData(newRootData);

            setCurrentData(data);

            // var newTranslation = {x:0, y: 0};
            // if (width > data.width) {
            //     newTranslation.x = (width - data.width) / 2;
            // }
            // setMapTranslation(newTranslation);

            setIsLoading(false);
          }
      );

      // si on a déjà auparavant cliqué sur cette zone et que les données sont déjà dans le rootData
      // on récupère les données de la zone dans le rootData et on les set en currentData
    } else if (
        rootDataArea.data &&
        rootDataArea.data.areas &&
        Object.keys(rootDataArea.data.areas).length
    ) {
      hideClickedModal();

      // on doit set en current data les data qui contiennent l'image bg, le type etc
      // ici on a le clicked area,
      setCurrentData(clickedArea.data);

      let initialScale = getScaleRate(isInBox,isFullVisible,refContainerMap.current.offsetWidth, refContainerMap.current.offsetHeight, clickedArea.data.width, clickedArea.data.height, 0.75);

      // on définit un état de scale (la taille du container divisée par la taille de l'image)
      // qui nous nous permettra d'afficher le svg en entier dans la fenetre, quelle que soit la taille de la fenetre
      setScaleState(initialScale);
      setInitialScale(initialScale);

      var newTranslation = { x: 0, y: 0 };
      setMapTranslation(newTranslation);
    }
  };

  // Vérifie si une zone sélectionnée contient la catégorie active
  const hasAreaActiveCategory = (area) => {
    var hasCategory = false;
    if (!currentCategory) {
      hasCategory = true;
    } else {
      area.cat.map((category) => {
        if (category == currentCategory) {
          hasCategory = true;
        }
      });
    }

    return hasCategory;
  };

  const hasReachedMaxSeats = () => {
    if (maxSeats === 0) {
      return true;
    }
    return (
        maxSeats &&
        ((!onBooked && bookedSeats.length >= maxSeats) ||
            (onBooked && selectedSeats.length >= maxSeats))
    );
  };

  // afficher le tooltip au hover d'une area de premier ou second niveau (zone ou siège)
  const hoverOnArea = (e, area) => {
    // si on a une règle d'enregistrée alors qu'on a pas atteint le nombre max de sièges
    // on reinitialise les règle
    if (warningRulesHTML && !hasReachedMaxSeats()) {
      setWarningRulesHTML(null);
    }
    let hasCategory = hasAreaActiveCategory(area);

    if (area.av === "1" && !clickedArea && hasCategory) {
      setCurrentElement(e.target);
      setHoveredArea(area);
    }
  };

  const hoverOutArea = (e) => {
    if (!clickedArea) {
      setCurrentElement(null);
      setHoveredArea(undefined);
    }
  };

  // lorsqu'une area a été touchée
  // (à inverser avec les appel d'api)
  const touchArea = (e, clickedArea) => {

    // Si on est en fin de drag, la bibli MapIntercation renvoie l'état preventedDefault à true
    // On controle donc que l'événement preventedDefault est à false pour être sûr qu'il s'agissait d'un "clic"
    setNavMode('mobile');

    if (!e.defaultPrevented) {
      hoverOnArea(e, clickedArea);
      window.setTimeout(() => {
        clickOnArea(e, clickedArea);
      }, 100);
    }

  };

  const addBookedSeat = (bookedSeat) => {
    let updatedBookedSeats = bookedSeats;
    updatedBookedSeats[updatedBookedSeats.length] = bookedSeat;

    setBookedSeats(updatedBookedSeats);
  };

  const closeClickedModal = (e) => {
    hideClickedModal();
  };

  const clickOnBack = () => {
    setCurrentData(rootData);

    let initialScale = getScaleRate(isInBox,isFullVisible,refContainerMap.current.offsetWidth, refContainerMap.current.offsetHeight, rootData.width, rootData.height, 0.75);

    // redéfinit le scale à la taille de l'image sur laquelle on retourne
    setScaleState(initialScale);
    setInitialScale(initialScale);

    var newTranslation = { x: 0, y: 0 };
    setMapTranslation(newTranslation);

    hideClickedModal();
  };

  const hideClickedModal = () => {
    if (refModal.current) {
      // refModal.current.style.display = "none";
      setHoveredArea(undefined);
      setClickedArea(null);
      setIsSeatArea(false);
    }
  };

  const handleSelectedSeat = (seat) => {
    selectedSeats.push(seat);
    onSeatSelected(selectedSeats, seat);

    removeFromAvailableHolders(seat.holderId);
  };

  const addWarningRulesHTML = (warningRulesHTML) => {
    setWarningRulesHTML(warningRulesHTML);
  };

  const addToAvailableHolders = (holderId) => {
    var newAvailableHolders = [];

    // Parcours tous les porteurs existants
    holders.forEach((holder, index) => {
      // Si le porteur est celui qui vient d
      if (parseInt(holder.id) === parseInt(holderId)) {
        newAvailableHolders.push(holder);
      } else {
        var isAvailable = false;
        availableHolders.forEach((availableHolder, index) => {
          if (parseInt(holder.id) === parseInt(availableHolder.id)) {
            isAvailable = true;
          }
        });

        if (isAvailable) {
          newAvailableHolders.push(holder);
        }
      }
    });

    setAvailableHolders(newAvailableHolders);
  };

  const removeFromAvailableHolders = (holderId) => {
    var newAvailableHolders = [];

    // Parcours tous les porteurs existants
    availableHolders.forEach((holder, index) => {
      // Si le porteur est celui qui vient d
      if (parseInt(holder.id) === parseInt(holderId)) {
        return;
      }

      newAvailableHolders.push(holder);
    });

    setAvailableHolders(newAvailableHolders);
  };

  useImperativeHandle(ref, () => ({
    unbook: (seatId) => {
      let updatedSeats = [];
      bookedSeats.forEach((seat, index) => {
        if (seat.id !== seatId) {
          updatedSeats.push(seat);
        }
      });

      setBookedSeats(updatedSeats);
      if (!updatedSeats.length) {
        setCartIdState(null);
      }

      // Récupère le siège, et le rend disponible
      let seat = currentData.areas[seatId];
      if (seat) {
        seat.av = "1";
        seat.sel = "0";

        // Met à jour les datas
        currentData.areas[seatId] = seat;
        setCurrentData(currentData);
      }
    },
    unselect: (seatId) => {
      // Récupère le siège, et le rend disponible
      let seat = currentData.areas[seatId];
      seat.av = "1";
      seat.sel = "0";

      // Met à jour les datas
      currentData.areas[seatId] = seat;
      setCurrentData(currentData);

      // Met à jour les sièges séléctionnés
      let updatedSeats = [];
      selectedSeats.forEach((seat, index) => {
        if (seat.id !== seatId) {
          updatedSeats.push(seat);
        } else {
          addToAvailableHolders(seat.holderId);
        }
      });
      setSelectedSeats(updatedSeats);
    },
    book: () => {
      //
      let selection = [];
      selectedSeats.forEach((seat, index) => {
        let area = currentData.areas[seat.id];
        selection.push({
          priceId: seat.priceId,
          categoryId: seat.categoryId,
          rateId: seat.rateId,
          seatId: seat.id,
          holderId: seat.holderId,
          additionalData: area.cust,
        });
      });

      // faire un set cart id
      Api.post(
          URL_ADD_SEAT(
              packId,
              sessionId,
              rootData.service,
              cartIdState,
              idwl,
              sessionList
          ),
          {
            selection,
            zone: rootData.zone,
          }
      ).then((res) => {
        if (res.data && res.data.typeError) {
          onBooked(res.data);
        } else {
          // remonte l'id panier via le setter parent
          setCartIdState(res.data.idWs);

          onBooked(res.data);
        }

        setIsLoading(false);
      });
    },
    showLoader: () => {
      setIsLoading(true);
    },
  }));

  // créé un objet de props pour la modal, car vu que c'est un forwardRef Component on ne peut passer qu'une
  // seule props et ref
  const modalProps = {
    area: hoveredArea,
    clickedArea: clickedArea,
    closeClickedModal: closeClickedModal,
    rootData: rootData,
    packId: packId,
    sessionId: sessionId,
    sessionList: sessionList,
    currentData: currentData,
    setCurrentData: setCurrentData,
    cartIdState: cartIdState,
    setCartIdState: setCartIdState,
    idwl: idwl,
    subscriptionId: subscriptionId,
    onSeatAdded: onSeatAdded,
    addBookedSeat: addBookedSeat,
    onBooked: onBooked,
    selectedSeats: selectedSeats,
    onSeatSelected: handleSelectedSeat,
    holders: availableHolders,
    colors: colors,
    language: language,
    addWarningRulesHTML: addWarningRulesHTML,
    warningRulesHTML: warningRulesHTML,
    authorizeDesignation: authorizeDesignation
  };

  let containerMapClasses = "containerMap";

  if (isLoading) {
    containerMapClasses += " loading";
  }

  if (isSeatArea) {
    containerMapClasses += " containerMap--overlay";
  }

  return (
      //si on reçoit des données

      <div className={"tk-map"}>
        {currentData && currentData.categories && (
            <MapFilter
                categories={currentData.categories}
                language={language}
                mapToggleCategory={toggleCategory}
                isVisibleRange={props.isVisibleRange}
            />
        )}

        {hoveredArea && hoveredArea.av ? (
            <ModalTooltip props={modalProps} ref={refModal} />
        ) : null}

        {currentData && (
            <div ref={refContainerMap} className={containerMapClasses}>
              {isLoading && <Loader></Loader>}

              {currentData.areas && (
                  <MapContainer
                      scaleState={scaleState}
                      currentData={currentData}
                      currentCategory={currentCategory}
                      refContainerMap={refContainerMap}
                      hoverOnArea={hoverOnArea}
                      hoverOutArea={hoverOutArea}
                      setScaleState={setScaleState}
                      initialScale={initialScale}
                      clickOnArea={clickOnArea}
                      touchArea={touchArea}
                      mapTranslation={mapTranslation}
                      onMapTranslationChange={handleMapTranslationChange}
                      inBox={inBox}
                      fullVisible={fullVisible}
                      isLoading={isLoading}
                      clickedArea={clickedArea}
                      colors={colors}
                      service={rootData.service}
                      view={rootData.view}
                  ></MapContainer>
              )}

              {currentData.type === "seats" && (
                  <div className="backButtonMap">
                    <p className="resetMargin__text" onClick={clickOnBack}>
                      {TEXTS[language].back}
                    </p>
                  </div>
              )}
            </div>
        )}
      </div>
  );
});

export default MainContainer;

MainContainer.defaultProps = {
  onSeatAdded: function () {},
  onSeatSelected: function () {},
  onBooked: false,
  maxSeats: null,
  inBox: false,
  autoload: true,
  language: "fr",
  colors: THEME_COLORS,
};
