function UpdateTraffic() {


    let regex = new RegExp(`(^| )traffic_id=([^;]+)`);
    let cookie = document.cookie.match(regex);

    let value =  null;
    if (!cookie) {
        value = (Math.random() * 1e17).toString() + Math.floor(Date.now() / 1000);
        document.cookie = "traffic_id=" + value;
    } else {
        value = cookie[2];
    }

    if (typeof trafficUrl !== 'undefined') {
        let url = trafficUrl.replace("token", value);
        fetch(url).then(res => res.json());
    }

}

export default UpdateTraffic;
