import React, {useState, useEffect, useRef} from "react";
import NumberFormat from "react-number-format";

import { TEXTS } from "../constants/language";

import UIkit from 'uikit';


// funct component qui récupère la props session ID
const MapFilter = ({categories, language, mapToggleCategory, isVisibleRange}) => {

    const [currentFilter, setCurrentFilter] = useState(null);


    const refSlider = useRef();


    const onFilterClicked = (idCategory) => {

        if (currentFilter && (currentFilter === idCategory)) {
            setCurrentFilter(null);
            mapToggleCategory(null);
        }
        else {
            setCurrentFilter(idCategory);
            mapToggleCategory(idCategory);
        }

    }



    useEffect(() => {

        UIkit.slider(refSlider.current);

    }, []);

    return (

        <div className={"filter--container"}>

            <h3 className="uk-text-large uk-margin-medium-bottom">{TEXTS[language].introduction} {categories.length > 1 && TEXTS[language].filter}</h3>

            {categories.length > 1 &&

                <div className="uk-position-relative uk-visible-toggle uk-margin-bottom uk-margin-remove-right"
                     id="slider-cat-plan" tabIndex="0" ref={refSlider}>

                    <ul className="uk-slider-items slider-cat-plan uk-grid uk-child-width-auto uk-position-relative">

                        {categories.map(category => {

                            // vérifie que la catégorie est bien active
                            // (une catégorie peut-être disabled lors de ventes échelonnées)
                            if (category.enabled === false) {
                                return ;
                            }

                            const categoryId = category.id;
                            const label = category.title;
                            const color = category.color;
                            const min = category.min ? parseFloat(category.min) : null;
                            const max = category.max ? parseFloat(category.max) : null;

                            let classes = "";
                            if (currentFilter && currentFilter !== categoryId) {
                                classes+= " filter--unhighlighted";
                            }

                            return (

                                <li key={categoryId} className={"slider-cat-item slider-cat-item--plan" + classes} onClick={(e) => onFilterClicked(categoryId)}>
                                    <span className="category-slider-item-color"
                                          style={{ backgroundColor: color }}></span>
                                    <span data-cat={label}>{label}</span><br />
                                    {isVisibleRange ?
                                        <span className="category-price">
                                            <strong className="uk-text-bold uk-text-small">
                                                de <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} value={min ? min : 0} suffix={' ' + currencySymbol}/> à <NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} value={max ? max : 0} suffix={' ' + currencySymbol}/>
                                            </strong>
                                        </span>
                                    : min ?
                                        <span className="category-price">
                                            <small className="uk-text-small">{TEXTS[language].from} </small>
                                            <strong className="uk-text-bold">
                                                <NumberFormat displayType={'text'} decimalScale={2}
                                                              fixedDecimalScale={true} decimalSeparator={","}
                                                              value={min} suffix={' ' + currencySymbol}/>
                                            </strong>
                                        </span>
                                    :
                                        <span className="category-price">
                                            <small className="uk-text-small">{TEXTS[language].from} </small>
                                            <strong className="uk-text-bold">
                                                0,00 {currencySymbol}
                                            </strong>
                                        </span>
                                    }
                                </li>

                            );

                        })}

                    </ul>

                    <a className="uk-position-center-left uk-position-small" href="#" data-uk-slidenav-previous
                       data-uk-slider-item="previous"></a>
                    <a className="uk-position-center-right uk-position-small" href="#" data-uk-slidenav-next
                       data-uk-slider-item="next"></a>

                </div>

            }

        </div>

    );

}

export default MapFilter;
