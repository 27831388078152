import React, { useEffect, useRef, useState, forwardRef } from "react";

import Api from "../utils/Api";
import { URL_ADD_SEAT } from "../constants/urls";
import { TEXTS } from "../constants/language";
import ModalCheckbox from "./ModalCheckbox";
import NumberFormat from "react-number-format";
import ModalHolder from "./ModalHolder";
import { getMinPrice } from "../utils/priceCalculator";

const ModalTooltip = forwardRef((props, ref) => {
  const {
    area,
    clickedArea,
    closeClickedModal,
    rootData,
    packId,
    sessionId,
    sessionList,
    currentData,
    setCurrentData,
    cartIdState,
    setCartIdState,
    idwl,
    subscriptionId,
    onSeatAdded,
    onBooked,
    addBookedSeat,
    selectedSeats,
    onSeatSelected,
    holders,
    colors,
    language,
    addWarningRulesHTML,
    warningRulesHTML,
    authorizeDesignation,
  } = props.props;

  // par défaut, le prix sélectionné est le premier de la liste, et le siège est celui sur lequel on est en hover
  const [selectedPriceSeat, setSelectedPriceSeat] = useState({
    priceId: area.pr ? area.pr[0] : "",
    seatId: area.id,
    additionalData: area.cust,
  });

  const refInputBtn = useRef();

  const [isAddingToCart, setIsAddingToCart] = useState(false);

  const [addToCartError, setAddToCartError] = useState(null);

  const backgroundToHover = (event) => {
    event.target.style.backgroundColor = colors.button.backgroundHover;
  };

  const backgroundToDefault = (event) => {
    event.target.style.backgroundColor = colors.button.background;
  };

  const selectedSeatPropback = (currentArea, rateId) => {
    const priceInfos = currentData.prices[rateId];

    const category = getCategory(currentArea.cat[0]);

    const selectedSeat = {};
    selectedSeat.id = currentArea.id;
    selectedSeat.row = currentArea.da.r;
    selectedSeat.seat = currentArea.da.p;
    selectedSeat.area = currentArea.ia;
    selectedSeat.color = currentArea.fill;
    selectedSeat.type = currentArea.typ;
    selectedSeat.typeLabel =
        currentArea.typ === "1"
            ? "Strapontin"
            : currentArea.typ === "2"
                ? "Debout"
                : "Fauteuil";
    selectedSeat.category = category.title;
    selectedSeat.categoryId = category.id;
    selectedSeat.rateId = priceInfos.id;
    selectedSeat.priceName = priceInfos.title;
    let amount = currentArea.pr[priceInfos.id];
    selectedSeat.price = amount;
    selectedSeat.priceLabel = amount.slice(0, (amount.indexOf(".")) + 2 + 1) + currencySymbol;

    return selectedSeat;
  };

  const getCategory = (idCategory) => {
    var category = false;
    currentData.categories.forEach(function (item, i) {
      if (item.id == idCategory) {
        category = item;
        return false;
      }
    });
    return category;
  };

  // A chaque fois que l'état currentElement est modifié
  useEffect(() => {
    if (clickedArea) {
      setSelectedPriceSeat({
        categoryId: clickedArea.cat ? clickedArea.cat[0] : "",
        rateId: clickedArea.pr ? currentData.prices[Object.keys(clickedArea.pr)[0]].id : "",
        seatId: clickedArea.id,
        additionalData: clickedArea.cust,
        holderId: holders.length ? holders[0].id : null,
      });
    } else {
      setSelectedPriceSeat({
        categoryId: area.cat ? area.cat[0] : "",
        seatId: area.id,
        additionalData: area.cust,
      });
    }
  }, [clickedArea]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // on créé une copie du rootData
    let newCurrentArea = currentData.areas[clickedArea.id];

    // on modifie la propriété available du currentArea
    newCurrentArea.av = false;
    newCurrentArea.sel = "1";

    // Si un callback onBooked est défini,
    // la validation est définie par un élément exterieur, on est donc en sélection multiple
    //
    // Si aucun callback n'est défini, on est en choix à la place
    if (!onBooked) {
      // faire appel à l'url de post vers le front en envoyant les données du selectedPriceSeat en content
      // et en param d'url on envoie le service, la sessionid et la carteId
      setIsAddingToCart(true);

      let selection = [];
      selection.push(selectedPriceSeat);

      // faire un set cart id
      Api.post(
          URL_ADD_SEAT(
              packId,
              sessionId,
              rootData.service,
              cartIdState,
              idwl,
              sessionList,
              subscriptionId
          ),
          {
            selection,
            zone: currentData.zone
          }
      ).then((res) => {
        if (res.data["error"]) {

          setAddToCartError(res.data.typeError);
          newCurrentArea.av = '1';
          newCurrentArea.sel = "0";

          // on met le current area dans une copie du currentData
          let newCurrentData = currentData;

          // on set le currentData à la copie du currentData
          newCurrentData.areas[clickedArea.id] = newCurrentArea;

          // on remplace le rootData par la copie du rootData mise à jour
          setCurrentData(newCurrentData);



        } else {
          // on met le current area dans une copie du currentData
          let newCurrentData = currentData;

          // on set le currentData à la copie du currentData
          newCurrentData.areas[clickedArea.id] = newCurrentArea;

          // on remplace le rootData par la copie du rootData mise à jour
          setCurrentData(newCurrentData);

          // ferme la modal
          closeClickedModal();

          // remonte l'id panier via le setter parent
          setCartIdState(res.data.idWs);

          // execute le callback de réservation
          let selectedSeat = selectedSeatPropback(
              newCurrentArea,
              selectedPriceSeat.rateId
          );
          onSeatAdded(res.data, selectedSeat);

          // execute le callback de réservation
          addBookedSeat(selectedSeat);
          if (res.data["warning"]) {
            addWarningRulesHTML(res.data["warning"]);
          }
        }

        setIsAddingToCart(false);
      }).catch(error => {
        setAddToCartError(TEXTS[language].unavailable);
        newCurrentArea.av = '1';
        newCurrentArea.sel = "0";

        // on met le current area dans une copie du currentData
        let newCurrentData = currentData;

        // on set le currentData à la copie du currentData
        newCurrentData.areas[clickedArea.id] = newCurrentArea;

        // on remplace le rootData par la copie du rootData mise à jour
        setCurrentData(newCurrentData);


      });
    } else {
      // ferme la modal
      closeClickedModal();

      let addedSeat = selectedSeatPropback(
          newCurrentArea,
          selectedPriceSeat.rateId
      );
      addedSeat.holderId = selectedPriceSeat.holderId;

      var holderName = "";
      holders.forEach(function (item, i) {
        if (item.id == selectedPriceSeat.holderId) {
          holderName = item.firstName + " " + item.lastName;
          return false;
        }
      });
      addedSeat.holderName = holderName;

      onSeatSelected(addedSeat);
    }
  };

  // au clic sur un prix, on change le prix et le siège sélectionné
  const handleCheckboxChange = (checkbox) => {

    setSelectedPriceSeat({
      categoryId: clickedArea.cat[0],
      rateId: checkbox.value,
      seatId: clickedArea.id,
      additionalData: area.cust,
    });

  };

  // Lorsqu'on sélectionne un porteur de billet dans la liste de sélection
  const handleHoldersChange = (holder) => {
    selectedPriceSeat.holderId = holder;
    setSelectedPriceSeat(selectedPriceSeat);
  };

  const jsUcfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isNumbered = (area) => {
    return area.typ !== "2";
  }

  const defaultColors = {
    color: colors.button.font,
    backgroundColor: colors.button.background,
  };

  let index = 0;

  return (
      <div className="modalMap" ref={ref}>
        {area.seat && clickedArea ? (
            <span
                href="#"
                onClick={closeClickedModal}
                onTouchEnd={closeClickedModal}
                className="modal__close"
            ></span>
        ) : null}

        <div className="modalInfosContainerMap">
          <div className="modal__InfoArea">
            <p className="modal__InfoArea__text resetMargin__text">
              {/* ia = infos area */}
              {area.ia}
            </p>
          </div>

          <div className={"modal__categories" + (area.typ === '2' ? ' last' : '')}>
            {area.cat.map((category) => {
              // const categoryName = rootData.categories[category];
              const categoryName = getCategory(category);

              return (
                  // afficher la cat dont l'id === category

                  <p
                      key={category}
                      className="modal__categories__text resetMargin__text"
                  >
                    {categoryName.title}
                  </p>
              );
            })}
          </div>

          {area.seat ? (
              <>
                {area.typ !== '2' ?
                    <p className="modal__price resetMargin__text">
                      {area.da.a && TEXTS[language].area + " " + area.da.a + " - "} {TEXTS[language].rank} {area.da.r} - {TEXTS[language].seat} {area.da.p}
                      {authorizeDesignation ?
                        <span className="uk-text-center uk-display-block">{area.designation}</span>
                      : area.typ === '1' ?
                        <span className="uk-text-bolder uk-text-center uk-display-block">Strapontin</span>
                        : area.typ === '0' ?
                        <span className="uk-text-center uk-display-block">Fauteuil</span>
                        : ''
                      }
                    </p>
                    : ''
                }
              </>
          ) : (
              <p className="modal__price resetMargin__text">
                {jsUcfirst(TEXTS[language].from)}{" "}
                <span className="bold">{getMinPrice(rootData, area)+ ' ' + currencySymbol}</span>
              </p>
          )}
        </div>

        {warningRulesHTML ? (
            <div
                className="modal__warningRules modal__addToCart__error"
                dangerouslySetInnerHTML={{ __html: warningRulesHTML }}
            ></div>
        ) : (
            <>
              {area.seat ? (
                  <div className="modal__addToCart__container">
                    {!addToCartError ? (
                        <form
                            onSubmit={handleSubmit}
                            className="modal__addToCart__form"
                        >
                          {Object.keys(area.pr).map(key => {

                            const priceInfos = currentData.prices[key];
                            const amount = area.pr[key];

                            if (priceInfos.enabled) {

                              return (
                                  <div key={key} className="modal__addToCart__price" data-cy={"availability-SeatPlanPrice-input"}>
                                    <label htmlFor={"addToCart__price__input" + key}>
                                      <div className="modal__addToCart__price__label">
                                        <span className="modal__addToCart__price__label__title">
                                          {rootData.service === 'dsp' ? priceInfos.title.toUpperCase() : priceInfos.title}
                                          {priceInfos.icon && (
                                              <i className={ `uk-margin-small-left price-icon ${priceInfos.icon}` } style={{color: priceInfos.iconColor}}></i>
                                          )}
                                        </span>
                                        <span className="modal__addToCart__price__label__amount">
                                          <NumberFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              decimalSeparator={","}
                                              value={amount * 1}
                                              suffix={currencySymbol}
                                          />
                                        </span>
                                      </div>
                                      <span className="modal__addToCart__price__label__description">
                              {priceInfos.description}
                            </span>
                                    </label>

                                    {clickedArea && (
                                        <ModalCheckbox
                                            colors={colors.checkbox}
                                            value={key}
                                            checked={0 === index++}
                                            onChange={handleCheckboxChange}
                                        />
                                    )}
                                  </div>
                              );

                            }

                          })}

                          {holders.length ? (
                              <ModalHolder
                                  holders={holders}
                                  onChange={handleHoldersChange}
                              />
                          ) : (
                              ""
                          )}

                          {clickedArea && !isAddingToCart ? (
                              <input
                                  ref={refInputBtn}
                                  type="submit"
                                  data-cy={"availability-validatePrice-btn"}
                                  className="modal__addToCart__price__validate uk-button-primary"
                                  value={TEXTS[language].validate}
                                  style={defaultColors}
                                  onMouseEnter={backgroundToHover}
                                  onMouseLeave={backgroundToDefault}
                              />
                          ) : null}

                          {clickedArea && isAddingToCart ? (
                              <div
                                  className="modal__addToCart__price__loading"
                                  style={defaultColors}
                              >
                                <div className="modal__addToCart__spinner">
                                  <div className="sk-fading-circle">
                                    <div className="sk-circle1 sk-circle"></div>
                                    <div className="sk-circle2 sk-circle"></div>
                                    <div className="sk-circle3 sk-circle"></div>
                                    <div className="sk-circle4 sk-circle"></div>
                                    <div className="sk-circle5 sk-circle"></div>
                                    <div className="sk-circle6 sk-circle"></div>
                                    <div className="sk-circle7 sk-circle"></div>
                                    <div className="sk-circle8 sk-circle"></div>
                                    <div className="sk-circle9 sk-circle"></div>
                                    <div className="sk-circle10 sk-circle"></div>
                                    <div className="sk-circle11 sk-circle"></div>
                                    <div className="sk-circle12 sk-circle"></div>
                                  </div>
                                  <p className="modal__addToCart__text resetMargin__text">
                                    {TEXTS[language].adding}
                                  </p>
                                </div>
                              </div>
                          ) : null}
                        </form>
                    ) : (
                        <div className="modal__addToCart__error resetMargin__text" dangerouslySetInnerHTML={{ __html: addToCartError }}/>
                    )}
                  </div>
              ) : null}
            </>
        )}
      </div>
  );
});

export default ModalTooltip;
