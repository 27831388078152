export const THEME_COLORS = {
    button: {
        background: '#012C6B',
        font : '#fff'
    },
    checkbox: {
        background: '#012C6B',
        font : '#fff'
    }
};
