import axios from "axios";

import { BASE_URL } from "../constants/urls";

// TODO
// instance d'axios, à checker le CORS
export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});
