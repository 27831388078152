import React, { useEffect, useRef, useState, forwardRef } from "react";


const ModalHolder = ({holders, onChange}) => {

    const options = [];
    holders.forEach((holder, index) => {

        options.push(
            <option value={holder.id}>{holder.firstName} {holder.lastName}</option>
        );

    });

    const onHolderChanged = (event) => {
        onChange(event.target.value)
    }

    return (

        <div className={"modal__holders_container"}>
            <p>Sélectionner le porteur de billet :</p>
            <select name={"holder"} onChange={onHolderChanged}>
                {options}
            </select>
        </div>

    )
}

export default ModalHolder;
