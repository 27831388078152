import React, { useEffect, useRef, useState, forwardRef } from "react";

import Api from "../utils/Api";
import { URL_ADD_SEAT } from "../constants/urls";
import { TEXTS } from "../constants/language";

const ModalCheckbox = ({colors, value, checked, onChange }) => {

    const [isChecked, setIsChecked] = useState(checked);

    const toggleChecked = (event) => {
        setIsChecked(event.target.checked);
        onChange(event.target)
    };

    const backStyle = {
        color: colors.font,
        backgroundColor: colors.background,
    };

    return (

        <div className={isChecked ? "bck-input checked" : "bck-input"} style={isChecked ? backStyle : {}}>
            <input
                type="radio"
                id={'addToCart__price__input' + value}
                name={'addToCart__price__input'}
                className="modal__addToCart__price__input"
                value={value}
                defaultChecked={checked}
                onChange={toggleChecked}
            />
        </div>

    )
}

export default ModalCheckbox;
